import {
  BASE_CURRENCY,
  ENDPOINTS,
  httpService,
  PaginatedResult,
  PagingOptions,
} from "@api";
import { AddAllowance, AllowanceDetails } from "@components";
import { useFetch } from "@hooks";
import { Action, ComplexHeader, Table } from "@shared";
import { usePayrollStore } from "@stores";
import {
  AllowanceStatus,
  FetchAllowancesDTO,
  TypeOfAllowance,
} from "@viewModels";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface Props {
  employeeId?: number;
  options?: PagingOptions;
  showInPopUp?: boolean;
  showActionButtons?: boolean;
}

const ListAllowances: React.FC<Props> = ({
  employeeId,
  options,
  showInPopUp = false,
  showActionButtons = true,
}) => {
  const clearSotrage = usePayrollStore((s) => s.clearStorage);

  const MySwal = withReactContent(Swal);

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchData = useFetch<PaginatedResult<FetchAllowancesDTO>>(
    {
      endPoint: ENDPOINTS.allowances,
      id: employeeId,
      reload: reload,
      queryStrings: options,
    },
    new PaginatedResult<FetchAllowancesDTO>()
  );

  useEffect(() => {}, [fetchData?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "employeeName", title: "Name" },
    { key: "allowanceTypeName", title: "Allowance Type" },
    {
      key: "amount",
      title: "amount",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
    {
      key: "type",
      title: "type",
      cellClass: (u: FetchAllowancesDTO) =>
        u.type === TypeOfAllowance.Bonus.toLowerCase()
          ? "badge rounded-pill bg-warning"
          : "badge rounded-pill bg-dark",
    },
    {
      key: "status",
      title: "status",
      cellClass: (u: FetchAllowancesDTO) =>
        u.status === AllowanceStatus.Finished.toLowerCase()
          ? "badge rounded-pill bg-secondary"
          : u.status === AllowanceStatus.Ongoing.toLowerCase()
          ? "badge rounded-pill bg-info"
          : "badge rounded-pill bg-danger",
    },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const actions: Action[] = !showActionButtons
    ? []
    : [
        {
          key: "",
          actionType: "badge",
          click: (a: FetchAllowancesDTO) => {
            MySwal.fire({
              showConfirmButton: false,
              allowOutsideClick: false,
              showCloseButton: true,
              width: 800,
              html: <AllowanceDetails allowanceId={a.id} />,
            });
          },
          title: "Details",
          color: "info",
        },
        {
          key: "",
          actionType: "badge",
          click: (d: FetchAllowancesDTO) => {
            onButtonClick(null, d);
          },
          title: "Edit",
          color: "warning",
          disable: (d: FetchAllowancesDTO) =>
            d.allowanceTypeName === "Overtime" ||
            d.status.toLowerCase() !== AllowanceStatus.Ongoing.toLowerCase(),
        },
        {
          key: "",
          actionType: "badge",
          click: (d: FetchAllowancesDTO) => {
            Swal.fire({
              title: "Are you sure you want to cancel this allowance?",
              text: "By confirming cancelation, this process is irrevocable, and this allowance will be permanently cancelled.",
              icon: "warning",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, cancel!",
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                const res = await httpService(ENDPOINTS.cancelAllowance).update(
                  d.id,
                  {}
                );
                if (res.status === 204) {
                  clearSotrage();
                  setReload((prev) => (prev === undefined ? true : !prev));
                  Swal.fire("Done!", "Allowance has been canceled.", "success");
                } else {
                  Swal.fire(
                    "Oops!",
                    "An error occurred, please try again later.",
                    "error"
                  );
                }
              },
            });
          },
          title: "Cancel",
          color: "danger",
          disable: (d: FetchAllowancesDTO) =>
            d.status !== AllowanceStatus.Ongoing.toLowerCase(),
        },
      ];

  const onButtonClick = (e: any, a?: FetchAllowancesDTO) => {
    if (!employeeId) return;
    e !== null && e.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <AddAllowance
          employeeId={employeeId}
          allowance={a}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const TableRenderer = () => {
    return (
      <Table
        actions={actions}
        class="table table-striped table-hover mb-0"
        data={fetchData?.data ?? []}
        headers={headers}
        isFetchingPage={fetchData.isFetching}
        showCounter
        // onPageChange={handlePageChange}
        paginationClass="row mt-3"
        showTotals
      />
    );
  };

  return (
    <>
      {showInPopUp ? (
        TableRenderer()
      ) : (
        <div className="col-10 offset-1 d-flex">
          <div className="card profile-box flex-fill">
            <div className="card-body">
              <h3 className="card-title">
                Allowances
                <a
                  href="!#"
                  onClick={(e) => onButtonClick(e)}
                  className="edit-icon"
                  data-bs-toggle="modal"
                  data-bs-target="#personal_info_modal"
                >
                  <i className="fa fa-plus" />
                </a>
              </h3>
              {TableRenderer()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListAllowances;
