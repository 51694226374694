import { ENDPOINTS, PaginatedResult } from "@api";
import { PaperHeader } from "@components";
import { useFetch } from "@hooks";
import { Department } from "@models";
import { ComplexHeader, Table, TableVerticalConfigs } from "@shared";
import { BranchSelection } from "@widgets";
import { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

type Search = {
  year: string;
  branchId: string | null;
  departmentId: string | null;
};

const EmployeeYearlyAppraisal = () => {
  const MySwal = withReactContent(Swal);
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [headers, setheaders] = useState<ComplexHeader[]>([]);
  const [searchParams, setSearchParams] = useState<Search>({
    year: queryStrings.get("year") ?? new Date().getFullYear().toString(),
    branchId: queryStrings.get("branchId") ?? null,
    departmentId: null,
  });

  const fetchData = useFetch<{}[]>(
    {
      endPoint: ENDPOINTS.employeeYearlyAppraisal,
      queryStrings: queryStrings,
      callbackParams: () => {
        queryStrings.set("year", searchParams.year);
        return queryStrings;
      },
    },
    []
  );

  const fetchDepartments = useFetch<PaginatedResult<Department>>(
    { endPoint: ENDPOINTS.departments, ignorePagination: true },
    new PaginatedResult<Department>()
  );

  useEffect(() => {
    // if (!searchParams.branchId) {
    //   navigate({ search: `` });
    // }

    setSearchParams({
      year: queryStrings.get("year") ?? new Date().getFullYear().toString(),
      branchId: queryStrings.get("branchId") ?? null,
      departmentId: queryStrings.get("departmentId") ?? null,
    });

    if (fetchData?.data?.length > 0) {
      var x: ComplexHeader[] = [];

      Object.keys(fetchData?.data[0]).forEach((d) => {
        x.push({ key: d, title: d.replaceAll("_", " ") });
      });

      setheaders(x);
    }
  }, [queryStrings, fetchData.isFetching]);

  const handleSearch = async () => {
    // if (!searchParams.branchId || !searchParams.year) return;

    setIsLoading(true);

    queryStrings.set("year", searchParams.year);
    searchParams?.branchId &&
      queryStrings.set("branchId", searchParams.branchId);
    searchParams?.departmentId &&
      queryStrings.set("departmentId", searchParams.departmentId);

    queryStrings.set("size", "-1");

    navigate({ search: `?${queryStrings.toString()}` });

    setIsLoading(false);
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="d-flex justify-content-between mb-1">
          <div className="col-4">
            <h3 className="page-title">Employee Yearly Appraisal</h3>
          </div>
        </div>
      </div>

      <div className="row mt-3" id="reportcontents">
        <PaperHeader title="Leave Balances Report" />
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex justify-content-start gap-2 col-6 mt-1">
            <div className="col">
              <div className="form-group mb-0 row">
                <label className="col-form-label col-auto">Year</label>
                <div className="col">
                  <input
                    type="number"
                    min="2022"
                    max="2099"
                    step="1"
                    defaultValue={searchParams.year}
                    className="form-control form-control-sm row"
                    onChange={(e) =>
                      setSearchParams((prev) => ({
                        ...prev,
                        year: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group mb-0 row">
                <label className="col-form-label col-auto">Branch</label>
                <div className="col">
                  <BranchSelection
                    className="form-control-sm row"
                    isSelectedOption={
                      searchParams?.branchId !== null
                        ? +searchParams?.branchId
                        : 0
                    }
                    returnValue={(val: number) =>
                      setSearchParams((prev) => ({
                        ...prev,
                        branchId: val?.toString(),
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="form-group mb-0 row">
                <label className="col-form-label col-auto">Department</label>
                <div className="col">
                  <select
                    className="form-control select"
                    style={{ height: "36px" }}
                    name="departmentId"
                    onChange={(e) => {
                      setSearchParams((prev) => ({
                        ...prev,
                        departmentId: e.target.value,
                      }));
                    }}
                  >
                    <option></option>
                    {fetchDepartments?.data?.items?.map(
                      (d: Department, i: any) => {
                        return (
                          <option
                            selected={
                              searchParams?.departmentId
                                ? +searchParams.departmentId === d.id
                                : false
                            }
                            key={i}
                            value={d.id}
                          >
                            {d.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto float-end ms-auto">
            <button
              disabled={isLoading}
              className="btn btn-sm btn-outline-info rounded-circle m-1 float-right"
              onClick={handleSearch}
            >
              <RiSearchLine />
            </button>
            {headers.length > 0 && (
              <TableVerticalConfigs
              // exportAsXSLS={onExport}
              // onPrint={{
              //   ref: "reportcontents",
              //   prePrint: onPrint,
              // }}
              />
            )}
          </div>
        </div>
        <div className="row">
          {headers.length > 0 && (
            <Table
              class="table table-striped table-hover mb-0"
              data={fetchData?.data ?? []}
              headers={headers}
              //   actions={actions}
              isFetchingPage={fetchData?.isFetching}
              showCounter
              // onPageChange={handlePageChange}
              paginationClass="row mt-3"
              // showTotals
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeYearlyAppraisal;
