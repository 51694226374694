import { ENDPOINTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { Branch, Role, User } from "@models";
import { AccessLevel } from "@viewModels";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

interface Props {
  callback?: () => void;
}

const RegisterUser: React.FC<Props> = ({ callback }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [handleAccessLevel, setHandleAccessLevel] = useState("");

  const fetchRoles = useFetch<PaginatedResult<Role>>(
    { endPoint: ENDPOINTS.roles },
    new PaginatedResult<Role>()
  );
  const fetchBranches = useFetch<PaginatedResult<Branch>>(
    { endPoint: ENDPOINTS.branches },
    new PaginatedResult<Branch>()
  );

  useEffect(() => {
    setIsLoading(fetchRoles.isFetching || fetchBranches.isFetching);
  }, [fetchRoles.isFetching, fetchBranches.isFetching]);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);
    setErrMsg("");

    if (data.password !== data.repeatedPassword) {
      setIsLoading(false);
      setErrMsg("Password and its confirmation are not the same.");
      return;
    }

    const user: Partial<User> = {
      id: 0,
      createdAt: "",
      userName: data.userName,
      password: data.password,
      roleId: +data.roleId,
      accessLevel: data.accessLevel,
      branchId: +data.branchId ?? 0,
    };

    const res = await httpService(ENDPOINTS.users).post(user);
    if (res.status === 201) {
      Swal.fire({
        icon: "success",
        text: "New user has been successfully registered.",
        showConfirmButton: false,
      });
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>Register New User</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          <div className="col d-flex justify-content-start">
            {errMsg && <small className="text-danger">* {errMsg}</small>}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>User Name</label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter user Name"
                  name="userName"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Password</label>
              <div className="col-12">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Password"
                  name="password"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Repeat Password</label>
              <div className="col-12">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Repeat Password"
                  name="repeatedPassword"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Role</label>
              <select
                className="form-control select"
                name="roleId"
                ref={register({ required: true })}
              >
                <option></option>
                {fetchRoles?.data?.items?.map((r: Role, i: any) => {
                  return (
                    <option key={i} value={r.id}>
                      {r.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Access Level</label>
              <select
                onChange={(e) => {
                  setHandleAccessLevel(e.target.value);
                }}
                className="form-control select"
                name="accessLevel"
                ref={register({ required: true })}
              >
                <option></option>
                {Object.keys(AccessLevel).map((d, i) => (
                  <option key={i} value={d}>
                    {d}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {handleAccessLevel === AccessLevel.Branch && (
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Branch</label>
                <select
                  className="form-control select"
                  name="branchId"
                  ref={register()}
                >
                  <option></option>
                  {fetchBranches?.data?.items?.map((r: Branch, i: any) => {
                    return (
                      <option key={i} value={r.id}>
                        {r.location} - {r.branchNumber}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        )}

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Register"}
        />
      </form>
    </>
  );
};

export default RegisterUser;
