import { BASE_CURRENCY, ENDPOINTS, httpService, PaginatedResult } from "@api";
import { PaperHeader } from "@components";
import { useFetch } from "@hooks";
import { PayrollGroup, SubBranch } from "@models";
import {
  ComplexHeader,
  ExportData,
  Table,
  TableVerticalConfigs,
} from "@shared";
import { PayrollPreviewVM } from "@viewModels";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type PayrollDetailsType = PayrollPreviewVM & {
  departmentName: string;
  branchName: string;
  payrollGroup: string;
  subBranchName: string;
};

const PayrollDetails = () => {
  const paryollId = useParams().id;

  const [isLoading, setIsLoading] = useState(false);
  const [payrollDetails, setPayrollDetails] = useState<PayrollDetailsType[]>(
    []
  );
  const [subBranches, setsubBranches] = useState<SubBranch[]>([]);
  const [selectedPayrollGroup, setSelectedPayrollGroup] = useState("");
  const [selectedSubBranchId, setSelectedSubBranchId] = useState("");

  let fetchData = useFetch<PayrollDetailsType[]>(
    { endPoint: ENDPOINTS.payrollDetails, id: paryollId },
    []
  );

  const fetchPayrollGroups = useFetch<PaginatedResult<PayrollGroup>>(
    {
      endPoint: ENDPOINTS.payrollGroups,
    },
    new PaginatedResult<PayrollGroup>()
  );

  useEffect(() => {
    if (fetchData?.data?.length > 0) {
      setPayrollDetails(fetchData.data);

      (async function () {
        console.log("branch id is: ", fetchData?.data[0]?.branchId);
        const subBranches = await httpService(ENDPOINTS.subBranches).getById(
          fetchData?.data[0]?.branchId ?? 0
        );
        if (subBranches.status === 200) setsubBranches(subBranches.data);
      })();
    }
  }, [paryollId, fetchData.isFetching, fetchPayrollGroups.isFetching]);

  useEffect(() => {
    if (selectedPayrollGroup || selectedSubBranchId) {
      setPayrollDetails(
        (prev) =>
          [
            ...fetchData?.data
              ?.filterIf(
                selectedPayrollGroup !== "",
                (f) => f.payrollGroup === selectedPayrollGroup
              )
              .filterIf(
                selectedSubBranchId !== "",
                (f) => f.subBranchName === selectedSubBranchId
              ),
          ] as PayrollDetailsType[]
      );
    } else {
      setPayrollDetails((prev) => [...fetchData?.data]);
    }
  }, [selectedPayrollGroup, selectedSubBranchId]);

  const headers: ComplexHeader[] = [
    { key: "branchName", title: "branch" },
    { key: "subBranchName", title: "Sub-Branch" },
    { key: "departmentName", title: "department" },
    { key: "payrollGroup", title: "payroll Group" },
    { key: "name", title: "Name" },
    { key: "phone", title: "phone" },
    { key: "accountNumber", title: "account Number" },
    {
      key: "allowances",
      title: "Allowances",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
    {
      key: "deductions",
      title: "deductions",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
    {
      key: "netSalary",
      title: "netSalary",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
    {
      key: "netAmount",
      title: "netAmount",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
  ];

  const onExport: ExportData = {
    data: () => Promise.resolve(fetchData.data),
    fileName: `Payroll Details Report - ${new Date().toLocaleDateString()}`,
  };

  const onPrint = (element: HTMLElement) => {
    var subElement = element.querySelectorAll(":scope > div")[0];
    subElement.classList.remove("d-none");

    var subElement02 = element.querySelectorAll(":scope > div")[1];
    subElement02.classList.add("d-none");

    let table = element.getElementsByTagName("table")[0];
    table.classList.remove("table-striped");
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="d-flex justify-content-between mb-1">
          <div className="col">
            <h3 className="page-title">Payroll Details Report</h3>
          </div>
          <div className="col">
            <select
              className="form-control select"
              name="leaveType"
              onChange={(e) => setSelectedPayrollGroup(e.target.value)}
            >
              <option></option>
              {fetchPayrollGroups?.data?.items?.map((l, idx) => (
                <option key={idx} value={l.name}>
                  {l.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <select
              className="form-control select"
              onChange={(e) => setSelectedSubBranchId(e.target.value)}
            >
              <option></option>
              {subBranches?.map((p, i: any) => {
                return (
                  <option key={i} value={p.name}>
                    {p.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="col-12 mt-3" id="reportcontents">
        <PaperHeader title="Payroll Details Report" />
        <div className="row">
          <div className="col-auto float-end ms-auto">
            <TableVerticalConfigs
              exportAsXSLS={onExport}
              onPrint={{
                ref: "reportcontents",
                prePrint: onPrint,
              }}
            />
          </div>
        </div>
        <Table
          // actions={actions}
          class="table table-striped table-hover mb-0"
          data={payrollDetails ?? []}
          headers={headers}
          isFetchingPage={fetchData.isFetching}
          showCounter
          // onPageChange={handlePageChange}
          paginationClass="row mt-3"
          // rowModifier={rowModifier}
          showTotals
        />
      </div>
    </div>
  );
};

export default PayrollDetails;
