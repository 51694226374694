import { ENDPOINTS, Operation, PaginatedResult } from "@api";
import { AddBranch, ListSubBranches } from "@components";
import { useFetch } from "@hooks";
import { Branch } from "@models";
import {
  Action,
  ComplexHeader,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ListBranches = () => {
  const [queryStrings] = useSearchParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchBranches = useFetch<PaginatedResult<Branch>>(
    {
      endPoint: ENDPOINTS.branches,
      queryStrings: queryStrings,
      reload: reload,
    },
    new PaginatedResult<Branch>()
  );

  useEffect(() => {}, [fetchBranches?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "id", title: "ID#" },
    { key: "location", title: "Branch Location" },
    { key: "branchNumber", title: "Branch Number" },
    { key: "payrollBranch?.location", title: "Payroll Branch" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const filters: Filterable<Branch>[] = [
    {
      key: "location",
      title: "location",
      format: "text",
      operation: Operation.like,
    },
    {
      key: "branchNumber",
      title: "number",
      format: "number",
      operation: Operation.eq,
    },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (b: Branch) => onAddUserClickHandler(b),
      title: "Edit",
      color: "warning",
    },
    {
      key: "",
      actionType: "badge",
      click: (b: Branch) => onsubBranchesClickHandler(b),
      title: "Sub-branches",
      color: "info",
    },
  ];

  const onAddUserClickHandler = (b?: Branch) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <AddBranch
          branch={b}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const onsubBranchesClickHandler = (b: Branch) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 800,
      html: <ListSubBranches branchId={b.id} branchName={b.location} />,
    });
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Branches</h3>
        </div>
        <div className="col-auto float-end ms-auto">
          <button
            className="btn btn-sm btn-primary rounded-circle m-1"
            onClick={() => onAddUserClickHandler()}
          >
            <FiPlus />
          </button>
          <TableVerticalConfigs filters={filters} />
        </div>
      </div>
      <div className="col-8 offset-2 mt-3">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={fetchBranches?.data ?? []}
          headers={headers}
          isFetchingPage={fetchBranches.isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>
    </div>
  );
};

export default ListBranches;
