import { ENDPOINTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { Deduction, DeductionType } from "@models";
import { useEmployeeStore } from "@stores";
import { TypeOfDeduction } from "@viewModels";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

class NewDeduction {
  employeeId: number;
  deductionTypeId: number;
  amount: number;
  numberOfMonths: number;
}

class EditDeduction {
  id: number;
  amount: number;
  numberOfMonths: number;
  operation: string;
}

interface Props {
  employeeId: number;
  deduction?: Deduction;
  callback?: () => void;
}

const AddDeduction: React.FC<Props> = ({ callback, deduction, employeeId }) => {
  const calculateIfSalaryIsDeductible = useEmployeeStore(
    (s) => s.calculateIfSalaryIsDeductible
  );

  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isAdvance, setIsAdvance] = useState(false);
  const [selectedTypeOfDeduction, setselectedTypeOfDeduction] =
    useState<TypeOfDeduction>(TypeOfDeduction.OneTimePayment);
  const [operation, setOperation] = useState<"Modify" | "TopUp">("Modify");
  

  const fetchDeductionType = useFetch<PaginatedResult<DeductionType>>(
    { endPoint: ENDPOINTS.deductionTypes },
    new PaginatedResult<DeductionType>()
  );

  useEffect(() => {}, [fetchDeductionType.isFetching]);

  useEffect(() => {
    (document.getElementById("amount") as HTMLInputElement).value =
      deduction?.id && operation === "Modify"
        ? deduction.amount.toString()
        : "0";
  }, [deduction?.id, operation]);

  const onSubmit = async (data: any, e: any) => {
    if (!employeeId) return;
    setIsLoading(true);

    const monthlyDeduction =
      parseFloat(data.amount) / parseFloat(data.numberOfMonths ?? 1);

    if (!isAdvance) {
      const isDeductible = await calculateIfSalaryIsDeductible(
        employeeId,
        monthlyDeduction
      );

      if (!isDeductible) {
        Swal.fire({
          icon: "warning",
          showConfirmButton: false,
          text: "The employee's expected salary is bellow the accepted percentage.",
        });
        setIsLoading(false);
        return;
      }
    }

    if (deduction?.id) {
      let obj: EditDeduction = {
        id: deduction.id,
        amount: data.amount,
        numberOfMonths: data.numberOfMonths,
        operation: operation,
      };

      var res = await httpService(ENDPOINTS.deductions).update(
        deduction.id,
        obj
      );
      if (res.status === 204) {
        callback?.();
        Swal.close();
      }
    } else {
      let dType: NewDeduction = {
        employeeId: employeeId,
        deductionTypeId: data.deductionTypeId,
        amount: data.amount,
        numberOfMonths: data.numberOfMonths,
      };

      var ress = await httpService(ENDPOINTS.deductions).post(dType);
      if (ress.status === 201) {
        callback?.();
        Swal.close();
      }
    }

    setIsLoading(false);
    e.target.reset();
  };

  const handleOnDeductionTypeChange = (e: any) => {
    const selected = fetchDeductionType?.data?.items?.find(
      (t) => t.id === +e.target.value
    );
    if (!selected) return;

    setselectedTypeOfDeduction(selected.type);
    setIsAdvance(selected.isAdvance);
  };

  return (
    <>
      <h4>{deduction?.id ? "Edit" : "Add"} deduction</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        {!deduction?.id && (
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>deduction Type</label>
                <select
                  className="form-control select"
                  name="deductionTypeId"
                  ref={register({ required: true })}
                  onChange={handleOnDeductionTypeChange}
                >
                  <option></option>
                  {fetchDeductionType?.data?.items?.map(
                    (r: DeductionType, i: any) => {
                      return (
                        <option key={i} value={r.id}>
                          {r.name}
                        </option>
                      );
                    }
                  )}
                </select>
                <span className="text-danger">
                  {errors.deductionTypeId && (
                    <span>This field is required</span>
                  )}
                </span>
              </div>
            </div>
          </div>
        )}

        {deduction?.id && (
          <div className="row mb-3">
            <div className="col">
              <p>Operation:</p>
            </div>

            <div className="form-check col">
              <input
                className="form-check-input"
                type="radio"
                name="filter"
                id="Modify"
                value="Modify"
                onClick={() => setOperation("Modify")}
                defaultChecked={operation === "Modify"}
              />
              <label className="form-check-label" htmlFor="Modify">
                Modify
              </label>
            </div>
            <div className="form-check col">
              <input
                className="form-check-input"
                type="radio"
                name="filter"
                id="TopUp"
                value="TopUp"
                onClick={() => setOperation("TopUp")}
                defaultChecked={operation === "TopUp"}
              />
              <label className="form-check-label" htmlFor="TopUp">
                Top Up
              </label>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>
                {deduction?.id && operation === "TopUp"
                  ? "Top up Amount"
                  : "Amount"}
              </label>
              <div className="col-12">
                <input
                  type="number"
                  step={0.01}
                  className="form-control"
                  // defaultValue={}
                  name="amount"
                  id="amount"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.amount && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>number Of Months</label>
              <div className="col-12">
                <input
                  type="number"
                  className="form-control"
                  name="numberOfMonths"
                  defaultValue={
                    deduction?.id
                      ? deduction.numberOfMonths
                      : selectedTypeOfDeduction ===
                        TypeOfDeduction.OneTimePayment
                      ? 1
                      : 0
                  }
                  disabled={
                    deduction?.id
                      ? false
                      : selectedTypeOfDeduction ===
                        TypeOfDeduction.OneTimePayment
                  }
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.numberOfMonths && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          {deduction?.id && operation === "TopUp" ? (
            <small className="text-danger h6">
              Top Up amount will be added to the current balance (
              {deduction?.ballance}$)
            </small>
          ) : (
            <div></div>
          )}
          <input
            type="submit"
            name="time"
            className="btn btn-primary"
            disabled={isLoading}
            value={
              isLoading
                ? "Please wait..."
                : fetchDeductionType.isFetching
                ? "Loading..."
                : "Submit"
            }
          />
        </div>
      </form>
    </>
  );
};

export default AddDeduction;
