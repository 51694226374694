import { ENDPOINTS, Operation, PaginatedResult, httpService } from "@api";
import { AddAppraisalTemplate, TemplateDetails } from "@components";
import { useFetch } from "@hooks";
import { AppraisalTemplate } from "@models";
import {
  Action,
  ComplexHeader,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import { AppraisalCycleType } from "@viewModels";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  format,
  addDays,
  startOfWeek,
  endOfWeek,
  startOfYear,
  startOfQuarter,
  endOfQuarter,
  addMonths,
  subDays,
  endOfMonth,
} from "date-fns";

interface Week {
  weekNumber: number;
  startDate: string;
  endDate: string;
}

interface Quarter {
  quarterNumber: number;
  startDate: string;
  endDate: string;
}

interface Month {
  monthNumber: number;
  startDate: string;
  endDate: string;
}

const ListAppraisalTemplates = () => {
  const [queryStrings] = useSearchParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchAppraisalTemplates = useFetch<PaginatedResult<AppraisalTemplate>>(
    {
      endPoint: ENDPOINTS.appraisalTemplates,
      queryStrings: queryStrings,
      reload: reload,
    },
    new PaginatedResult<AppraisalTemplate>()
  );

  useEffect(() => {}, [fetchAppraisalTemplates?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "name", title: "Template Name" },
    { key: "cycleType", title: "cycle Type" },
    { key: "kRAsTotalPercentage", title: "kRAs %" },
    { key: "evaluationCriteriaTotalPercentage", title: "EC %" },
    { key: "createdBy", title: "By" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const filters: Filterable<AppraisalTemplate>[] = [
    { key: "name", title: "name", format: "text", operation: Operation.like },
    {
      key: "cycleType",
      title: "Cycle Type",
      format: "select",
      data: [
        ...Object.keys(AppraisalCycleType)?.map((d) => ({
          id: d,
          name: d.replace("_", " ").capitalize(),
        })),
      ],
    },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (d: AppraisalTemplate) => {
        onViewClickHandler(d);
      },
      title: "View",
      color: "dark",
    },
    {
      key: "",
      actionType: "badge",
      click: (d: AppraisalTemplate) => {
        onDeleteClickHandler(d);
      },
      title: "Delete",
      color: "danger",
    },
  ];

  const onDeleteClickHandler = (l: AppraisalTemplate) => {
    Swal.fire({
      title: "Appraisal Template Deletion",
      text: `Are you sure you want to delete this record.`,
      icon: "warning",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I confirm!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const res = await httpService(ENDPOINTS.appraisalTemplates).delete(
          l.id
        );
        if (res.status === 204) {
          setReload((prev) => (prev === undefined ? true : !prev));
          Swal.fire(
            "Done!",
            "Appraisal Template has been deleted successfully.",
            "success"
          );
        }
      },
    });
  };

  const onViewClickHandler = (d: AppraisalTemplate) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 1000,
      html: <TemplateDetails templateId={d.id} />,
    });
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const getWeeksInYear = (year: number): Week[] => {
    const weeks: Week[] = [];
    let startDate = startOfYear(new Date(year, 0, 1));
    let currentDate = startDate;

    while (format(currentDate, "yyyy") === String(year)) {
      const weekNumber = parseInt(format(currentDate, "w"), 10);
      const endDate = endOfWeek(currentDate);
      weeks.push({
        weekNumber,
        startDate: format(currentDate, "yyyy-MM-dd"),
        endDate: format(endDate, "yyyy-MM-dd"),
      });
      currentDate = addDays(endDate, 1);
    }

    return weeks;
  };

  const getQuartersInYear = (year: number): Quarter[] => {
    const quarters: Quarter[] = [];
    let startDate = startOfYear(new Date(year, 0, 1));

    for (let i = 0; i < 4; i++) {
      const quarterNumber = i + 1;
      const startMonth = i * 3;
      const quarterStartDate = addMonths(startDate, startMonth);
      const quarterEndDate = subDays(addMonths(quarterStartDate, 3), 1);
      quarters.push({
        quarterNumber,
        startDate: format(quarterStartDate, "yyyy-MM-dd"),
        endDate: format(quarterEndDate, "yyyy-MM-dd"),
      });
    }

    return quarters;
  };

  const getMonthsInYear = (year: number): Month[] => {
    const months: Month[] = [];
    let startDate = startOfYear(new Date(year, 0, 1));

    for (let i = 0; i < 12; i++) {
      const monthNumber = i + 1;
      const monthStartDate = addMonths(startDate, i);
      const monthEndDate = endOfMonth(monthStartDate);
      months.push({
        monthNumber,
        startDate: format(monthStartDate, "yyyy-MM-dd"),
        endDate: format(monthEndDate, "yyyy-MM-dd"),
      });
    }

    return months;
  };

  // Example usage
  // const weeksIn2023 = getWeeksInYear(2023);
  // console.log("weeks are:", weeksIn2023);

  // const QuartersIn2023 = getQuartersInYear(2023);
  // console.log("Quarters are:", QuartersIn2023);

  const MonthsIn2023 = getMonthsInYear(2023);
  console.log("months are:", MonthsIn2023);

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Appraisal Templates</h3>
        </div>
        <div className="col-auto float-end ms-auto">
          <TableVerticalConfigs filters={filters} />
        </div>
      </div>
      <div className="col-8 offset-2 mt-3">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={fetchAppraisalTemplates?.data ?? []}
          headers={headers}
          isFetchingPage={fetchAppraisalTemplates.isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>
    </div>
  );
};

export default ListAppraisalTemplates;
