import { ENDPOINTS, httpService } from "@api";
import { TextEditor } from "@components";
import { JobOffer } from "@models";
import { DateHelpers } from "@shared";
import { JobEducationLevel, JobType } from "@viewModels";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  callback?: () => void;
  offer?: JobOffer;
}

const AddJobOffer = ({ callback, offer }: Props) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const textEditor = useRef("");

  useEffect(() => {
    if (offer?.description) textEditor.current = offer.description;
  }, [offer]);

  const onSubmit = async (data: any, e: any) => {
    setErrMsg("");
    if (!textEditor.current) {
      setErrMsg("Please refresh your page and try again.");
      return;
    }
    setIsLoading(true);

    const newOffer: Partial<JobOffer> = {
      title: data.title,
      description: textEditor.current,
      expiryDate: data.expiryDate,
      location: data.location,
      type: data.type,
      levelOfEducation: data.levelOfEducation,
    };

    let res;
    if (offer?.id) {
      newOffer.id = offer.id;
      newOffer.isPublished = data.isPublished;
      newOffer.isClosed = data.isClosed;

      res = await httpService(ENDPOINTS.jobOffers).update(offer.id, newOffer);
    } else {
      res = await httpService(ENDPOINTS.jobOffers).post(newOffer);
    }

    if (res.status === 201 || res.status === 204) {
      callback?.();
      e.target.reset();
      textEditor.current = "";
    }

    setIsLoading(false);
  };

  const onEditorChange = (val: string) => {
    textEditor.current = val;
  };

  return (
    <>
      <h4>New Aplication</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          <div className="col d-flex justify-content-start">
            {errMsg && <small className="text-danger">* {errMsg}</small>}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Title</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={offer?.title ?? ""}
                  className="form-control"
                  placeholder="Enter title"
                  name="title"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Location</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={offer?.location ?? ""}
                  className="form-control"
                  placeholder="Enter location"
                  name="location"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Expiry Date</label>
              <div className="col-12">
                <input
                  type="date"
                  defaultValue={DateHelpers.setInputDate(offer?.expiryDate)}
                  className="form-control"
                  placeholder="Enter expiryDate"
                  name="expiryDate"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Job Type</label>
              <select
                className="form-control select"
                name="type"
                ref={register({ required: true })}
              >
                <option></option>
                {Object.keys(JobType).map((d, i) => (
                  <option
                    key={i}
                    value={d}
                    selected={offer?.type.toUpperCase() === d.toUpperCase()}
                  >
                    {d.replace("_", " ")}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Education Level</label>
              <select
                className="form-control select"
                name="levelOfEducation"
                ref={register({ required: true })}
              >
                <option></option>
                {Object.keys(JobEducationLevel).map((d, i) => {
                  console.log("offer: ", offer?.levelOfEducation, d);
                  return (
                    <option
                      key={i}
                      value={d}
                      selected={
                        offer?.levelOfEducation.toUpperCase() ===
                        d.toUpperCase()
                      }
                    >
                      {d.replace("_", " ")}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <div
          style={{
            height: "100%",
            flex: "1",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextEditor
            text={offer?.description ?? ""}
            callback={onEditorChange}
          />
        </div>

        <div className="mt-5 d-flex justify-content-between">
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name="isPublished"
              id="isPublished"
              ref={register()}
              disabled={!offer}
              defaultChecked={offer?.isPublished ?? false}
            />
            <label className="form-check-label pt-1" htmlFor="isPublished">
              Publish Offer
            </label>
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name="isClosed"
              id="isClosed"
              ref={register()}
              disabled={!offer}
              defaultChecked={offer?.isClosed ?? false}
            />
            <label className="form-check-label pt-1" htmlFor="isClosed">
              Close Offer
            </label>
          </div>

          <input
            type="submit"
            name="time"
            className="btn btn-primary"
            disabled={isLoading}
            value={isLoading ? "Please wait..." : offer ? "Update" : "Register"}
          />
        </div>
      </form>
    </>
  );
};

export default AddJobOffer;
