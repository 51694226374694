import { ENDPOINTS, Operation, PaginatedResult } from "@api";
import {
  ChangePassword,
  ChangeRole,
  RegisterUser,
  UserApps,
} from "@components";
import { useFetch } from "@hooks";
import { User } from "@models";
import {
  Action,
  ComplexHeader,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ListUsers = () => {
  const [queryStrings] = useSearchParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchUsers = useFetch<PaginatedResult<User>>(
    { endPoint: ENDPOINTS.users, queryStrings: queryStrings, reload: reload },
    new PaginatedResult<User>()
  );

  useEffect(() => {}, [fetchUsers?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "userName", title: "User Name" },
    { key: "employee?.name", title: "Full Name" },
    { key: "employee?.branch?.location", title: "User Name" },
    { key: "role?.name", title: "Role" },
    { key: "accessLevel", title: "Access Level" },
    { key: "employee?.branch?.location", title: "Branch Location" },
    { key: "employee?.branch?.branchNumber", title: "Branch Number" },
    {
      key: "isActive",
      title: "Status",
      formatter: (isActive) => (isActive ? "Active" : "Not Active"),
      cellClass: (u: User) =>
        u.isActive ? "badge badge-success" : "badge badge-danger",
    },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const filters: Filterable<User>[] = [
    {
      key: "userName",
      title: "user name",
      format: "text",
      operation: Operation.like,
    },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (u: User) => {
        showUserApps(u);
      },
      title: "Apps",
      color: "warning",
    },
    {
      key: "",
      actionType: "badge",
      click: (u: User) => {
        onChangeRoleClickHandler(u);
      },
      title: "Set Role",
      color: "dark",
    },
    {
      key: "",
      actionType: "badge",
      click: () => {},
      title: "Deactivate",
      color: "danger",
    },
    {
      key: "",
      actionType: "badge",
      click: (u: User) => {
        changePassword(u);
      },
      title: "Reset Password",
      color: "primary",
    },
  ];

  const onAddUserClickHandler = () => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 900,
      html: (
        <RegisterUser
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const onChangeRoleClickHandler = (user: User) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 700,
      html: (
        <ChangeRole
          user={user}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const showUserApps = (user: User) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <UserApps
          userId={user.id}
          apps={user?.apps ?? []}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const changePassword = (user: User) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: <ChangePassword userId={user.id} isResetPassword />,
    });
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Users List</h3>
        </div>
        <div className="col-auto float-end ms-auto">
          <button
            className="btn btn-sm btn-primary rounded-circle m-1"
            onClick={onAddUserClickHandler}
          >
            <FiPlus />
          </button>
          <TableVerticalConfigs filters={filters} />
        </div>
      </div>

      <div className="col-12 mt-3">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={fetchUsers?.data ?? []}
          headers={headers}
          isFetchingPage={fetchUsers.isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>
    </div>
  );
};

export default ListUsers;
