import { ENDPOINTS, httpService } from "@api";
import { Shift } from "@models";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  callback?: () => void;
  shift?: Shift;
}

const AddShift: React.FC<Props> = ({ callback, shift }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    let oShift: Partial<Shift> = {
      shiftName: data.shiftName,
      startTime: data.startTime,
      endTime: data.endTime,
      workingHours: +data.workingHours,
      breakTimeHours: +data.breakTimeHours,
    };

    if (shift?.id) {
      oShift.id = shift.id;
      await httpService(ENDPOINTS.shifts).update(shift.id, oShift);
    } else {
      await httpService(ENDPOINTS.shifts).post(oShift);
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>{shift?.id ? "Edit" : "Add"} Shift</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Shift Name</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={shift?.shiftName ?? ""}
                  className="form-control"
                  name="shiftName"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.shiftName && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Start Time</label>
              <div className="col-12">
                <input
                  type="time"
                  defaultValue={shift?.startTime ?? ""}
                  className="form-control"
                  name="startTime"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.startTime && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>End Time</label>
              <div className="col-12">
                <input
                  type="time"
                  defaultValue={shift?.endTime ?? ""}
                  className="form-control"
                  name="endTime"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.endTime && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Working Hours</label>
              <div className="col-12">
                <input
                  type="number"
                  defaultValue={shift?.workingHours ?? ""}
                  className="form-control"
                  name="workingHours"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.workingHours && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Break Time Hours</label>
              <div className="col-12">
                <input
                  type="number"
                  defaultValue={shift?.breakTimeHours ?? ""}
                  className="form-control"
                  name="breakTimeHours"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.breakTimeHours && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={
            isLoading ? "Please wait..." : shift?.id ? "Update" : "Register"
          }
        />
      </form>
    </>
  );
};

export default AddShift;
