import { ENDPOINTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { DocumentType, Employee } from "@models";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  callback?: () => void;
  employee?: Employee;
}

const AddDocument: React.FC<Props> = ({ callback, employee }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const fetchDocTypes = useFetch<PaginatedResult<DocumentType>>(
    { endPoint: ENDPOINTS.documentTypes },
    new PaginatedResult<DocumentType>()
  );

  useEffect(() => {}, [fetchDocTypes.isFetching]);

  const onSubmit = async (data: any, e: any) => {
    if (!employee?.id) return;

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append("employeeId", employee?.id?.toString());
    formData.append("documentTypeId", data.documentTypeId);
    formData.append("description", data.description);

    await httpService(ENDPOINTS.employeeDocs).post(formData);

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>Upload Document</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Documet Type</label>
              <select
                className="form-control select"
                name="documentTypeId"
                ref={register({ required: true })}
              >
                <option></option>
                {fetchDocTypes?.data?.items?.map((r: DocumentType, i: any) => {
                  return (
                    <option key={i} value={r.id}>
                      {r.name}
                    </option>
                  );
                })}
              </select>
              <span className="text-danger">
                {errors.documentTypeId && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label htmlFor="file">Select File</label>
              <input
                type="file"
                name="file"
                id="file"
                className="form-control"
                ref={register({ required: true })}
              />
              <span className="text-danger">
                {errors.file && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="form-group">
              <label htmlFor="description">Remarks</label>
              <textarea
                className="form-control mb-4"
                name="description"
                id="description"
                aria-label="With textarea"
                ref={register({ required: true })}
              ></textarea>
              <span className="text-danger">
                {errors.description && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Register"}
        />
      </form>
    </>
  );
};

export default AddDocument;
