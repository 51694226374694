import { BASE_CURRENCY, ENDPOINTS, httpService } from "@api";
import {
  AddEmployee,
  EditPersonalInfo,
  EmployeeSalary,
  ListEmployeeEmergencyContacts,
  ListEmployeeDocs,
  ListAllowances,
  ListOverTimes,
  ListDeductions,
  ListLeaves,
  AttachUser,
  ListEncashments,
} from "@components";
import { useFetch, useLocalStorage } from "@hooks";
import { Employee } from "@models";
import { useEmployeeStore } from "@stores";
import { MyRingLoader } from "@shared";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

type ProfileSections =
  | "Profile"
  | "Salary"
  | "Documents"
  | "Allowances"
  | "OverTimes"
  | "Deductions"
  | "Leaves"
  | "Encashment";

const Profile = () => {
  const expectedSalary = useEmployeeStore((s) => s.expectedSalary);
  const calculateSalary = useEmployeeStore((s) => s.calculateSalary);
  const calculateIfSalaryIsDeductible = useEmployeeStore((s) => s.calculateIfSalaryIsDeductible);

  const MySwal = withReactContent(Swal);
  const employeeId = useParams().id;

  const [imagePrivew, setImagePrivew] = useState<string | null>(null);
  const [reload, setReload] = useState<boolean | undefined>(undefined);
  // const [section, setSection] = useState<ProfileSections>("Profile");

  const employee = useFetch<Employee>({ endPoint: ENDPOINTS.employees, id: employeeId, reload }, new Employee());

  const [selectedSection, setSelectedSection] = useLocalStorage<string>("selectedProfileSection", "Profile");

  useEffect(() => {
    if (selectedSection) {
      // setSection(selectedSection as ProfileSections);
      onSectionSelectionHandler(null, selectedSection as ProfileSections);
    }
    if (employee?.data?.imgUrl) setImagePrivew(employee?.data?.imgUrl ?? null);
  }, [employeeId, employee?.isFetching, employee?.data?.imgUrl]);

  useEffect(() => {
    if (employee.data.id)
      if (employeeId) {
        calculateSalary(+employeeId);
        calculateIfSalaryIsDeductible(+employeeId).then();
      }
  }, [employee.data.id, employeeId, expectedSalary]);

  const onBasicInfoEditClickHandler = (e: any) => {
    e.preventDefault();

    if (employee.isFetching) return;

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 1200,
      html: <AddEmployee employee={employee.data} callback={reFetchData} />,
    });
  };

  const onPersonalInfoEditClickHandler = (e: any) => {
    e.preventDefault();

    if (employee.isFetching) return;

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 800,
      html: <EditPersonalInfo employee={employee.data} callback={reFetchData} />,
    });
  };

  const reFetchData = () => {
    setReload((prev) => (prev === undefined ? true : !prev));
  };

  const handleImageSelection = (e: any) => {
    e.preventDefault();

    (document.querySelector("#uploadfile") as HTMLInputElement).click();
  };

  const handleImageUpload = async (e: any) => {
    e.preventDefault();

    if (e.target.files.length < 1 || !employeeId) return;

    setImagePrivew(URL.createObjectURL(e.target.files[0]));

    const formData = new FormData();
    formData.append("profileImg", e.target.files[0]);

    await httpService(ENDPOINTS.uploadImage).update(employee?.data?.id || 0, formData);
    reFetchData();
  };

  function onSectionSelectionHandler(e: any, selectedSection: ProfileSections) {
    e?.preventDefault();
    // if (e) {
    //   e.currentTarget.classList.toggle("active");
    // }

    // var profileNav = document
    //   .querySelector(".profileNav")
    //   ?.querySelectorAll("a");

    // profileNav?.forEach((nav) => nav.classList.remove("active"));
    // profileNav?.forEach((el) => {
    //   if (el.innerHTML.trim().toLowerCase() === section.trim().toLowerCase())
    //     el.classList.add("active");
    // });

    // setSection(selectedSection);
    setSelectedSection(selectedSection);
  }

  const changeContractStatus = async () => {
    if (employee.isFetching) return;
    if (!employee.data.contractStatus) return console.log("not implemented yet...");

    await Swal.fire({
      title: "Termination Reason",
      input: "select",
      inputOptions: {
        Discharge: "Discharge",
        Resignation: "Resignation",
        Layoff: "Layoff",
        PermanentDisability: "Permanent Disability",
        Retirement: "Retirement",
        Death: "Death",
      },
      inputPlaceholder: "Select reason",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise(async (resolve) => {
          if (value) {
            const obj = {
              employeeId: employee.data.id,
              reason: value,
              remarks: "-",
            };

            const res = await httpService(ENDPOINTS.terminateEmployee).update(employee.data.id, obj);
            if (res.status === 204) {
              setReload((prev) => (prev === undefined ? true : !prev));
              Swal.fire("terminated!", "Employee has been terminate.", "success");
            }
            // resolve(value);
          } else {
            resolve("You need to select a termination reason");
          }
        });
      },
    });
  };

  const handleUserConfig = async (e: any) => {
    e.preventDefault();

    if (employee.data.userId) {
      Swal.fire({
        showConfirmButton: false,
        text: `Employee Attached to a user `,
      });
      return;
    }

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <AttachUser
          employeeId={employee.data.id}
          callback={() => setReload((prev) => (prev === undefined ? true : !prev))}
        />
      ),
    });
  };

  if (employee.isFetching) {
    return <MyRingLoader />;
  }

  return (
    <>
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <h3 className="page-title">Profile</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">Employees</li>
                <li className="breadcrumb-item active">{employee?.data?.name}</li>
              </ul>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className="card mb-0">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="profile-view">
                  <div className="profile-img-wrap">
                    <div className="profile-img">
                      <a href="!#" onClick={(e) => handleImageSelection(e)}>
                        <img src={imagePrivew ?? `/assets/img/profiles/avatar-02.jpg`} alt="" />
                      </a>
                      <input
                        type="file"
                        name="uploadfile"
                        hidden={true}
                        accept="image/*"
                        id="uploadfile"
                        className="col-8 offset-1"
                        onChange={(e: any) => handleImageUpload(e)}
                      />
                    </div>
                  </div>
                  <div className="profile-basic">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="profile-info-left">
                          <h3 className="user-name m-t-0 mb-0">{employee?.data?.name}</h3>
                          <h6 className="text-muted">{employee?.data?.department?.name}</h6>
                          <small className="text-muted">{employee?.data?.designation?.name}</small>
                          <div className="staff-id">Employee ID : {employee?.data?.employeeId ?? "N/A"}</div>
                          <div className="small doj text-muted">
                            Date of Join :{" "}
                            {employee?.data?.dateOfEmployment
                              ? new Date(employee?.data?.dateOfEmployment).toLocaleDateString()
                              : "N/A"}
                          </div>
                          <div className="small doj text-muted">
                            Annual Leave : {employee?.data?.leaveBalance?.balance ?? "N/A"}
                          </div>
                          {/* <div className="staff-msg"><a className="btn btn-custom" href="chat.html">Send Message</a></div> */}
                          <small className="text-muted mt-3">
                            Expected Salary:
                            {expectedSalary !== null ? `${BASE_CURRENCY}${expectedSalary}` : " Calculating..."}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <ul className="personal-info">
                          <li>
                            <div className="title">Phone:</div>
                            <div className="text">
                              <a href="!#">{employee?.data?.phone}</a>
                            </div>
                          </li>
                          <li>
                            <div className="title">Email:</div>
                            <div className="text">
                              <a href="!#">{employee?.data?.email ?? "N/A"}</a>
                            </div>
                          </li>
                          <li>
                            <div className="title">Gender:</div>
                            <div className="text">{employee?.data?.gender ?? "N/A"}</div>
                          </li>
                          <li>
                            <div className="title">Branch:</div>
                            <div className="text">
                              {employee?.data?.branch?.location}
                              {employee?.data?.subBranch?.name && <> - {employee?.data?.subBranch?.name}</>}
                            </div>
                          </li>
                          <li>
                            <div className="title">Type of contract:</div>
                            <div className="text">{employee?.data?.typeOfContract}</div>
                          </li>
                          <li>
                            <div className="title">Contract Status:</div>
                            <div className="text" style={{ cursor: "pointer" }} onClick={changeContractStatus}>
                              {employee?.data?.contractStatus === true ? (
                                <span className="badge badge-success">Active</span>
                              ) : (
                                <span className="badge badge-danger">Not Active</span>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="pro-edit">
                    <a
                      data-bs-target="#profile_info"
                      data-bs-toggle="modal"
                      className="edit-icon"
                      href="!#"
                      onClick={onBasicInfoEditClickHandler}
                    >
                      <i className="fa fa-pencil" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card tab-box">
          <div className="row user-tabs">
            <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
              <ul className="nav nav-tabs nav-tabs-bottom profileNav">
                <li className="nav-item">
                  <a
                    href="!#"
                    onClick={(e) => onSectionSelectionHandler(e, "Profile")}
                    className={selectedSection === "Profile" ? "nav-link active" : "nav-link"}
                  >
                    Profile
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="!#"
                    onClick={(e) => onSectionSelectionHandler(e, "Salary")}
                    className={selectedSection === "Salary" ? "nav-link active" : "nav-link"}
                  >
                    Salary
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="!#"
                    onClick={(e) => onSectionSelectionHandler(e, "Documents")}
                    className={selectedSection === "Documents" ? "nav-link active" : "nav-link"}
                  >
                    Documents
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="!#"
                    onClick={(e) => onSectionSelectionHandler(e, "Allowances")}
                    className={selectedSection === "Allowances" ? "nav-link active" : "nav-link"}
                  >
                    Allowances
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="!#"
                    onClick={(e) => onSectionSelectionHandler(e, "OverTimes")}
                    className={selectedSection === "OverTimes" ? "nav-link active" : "nav-link"}
                  >
                    OverTimes
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="!#"
                    onClick={(e) => onSectionSelectionHandler(e, "Deductions")}
                    className={selectedSection === "Deductions" ? "nav-link active" : "nav-link"}
                  >
                    Deductions
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="!#"
                    onClick={(e) => onSectionSelectionHandler(e, "Leaves")}
                    className={selectedSection === "Leaves" ? "nav-link active" : "nav-link"}
                  >
                    Leaves
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="!#"
                    onClick={(e) => onSectionSelectionHandler(e, "Encashment")}
                    className={selectedSection === "Encashment" ? "nav-link active" : "nav-link"}
                  >
                    Encashment
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="!#"
                    onClick={(e) => {
                      handleUserConfig(e);
                    }}
                    className="nav-link"
                  >
                    User
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="tab-content">
          {selectedSection === "Profile" && (
            <div id="emp_profile" className="pro-overview tab-pane fade show active">
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <h3 className="card-title">
                        Personal Informations
                        <a
                          href="!#"
                          onClick={onPersonalInfoEditClickHandler}
                          className="edit-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#personal_info_modal"
                        >
                          <i className="fa fa-pencil" />
                        </a>
                      </h3>
                      <ul className="personal-info">
                        <li>
                          <div className="title">Passport No.</div>
                          <div className="text">{employee?.data?.passportNo ?? "N/A"}</div>
                        </li>
                        <li>
                          <div className="title">Passport Exp Date.</div>
                          <div className="text">{employee?.data?.passportExpiry ?? "N/A"}</div>
                        </li>
                        <li>
                          <div className="title">Nationality</div>
                          <div className="text">{employee?.data?.country ?? "N/A"}</div>
                        </li>
                        <li>
                          <div className="title">Religion</div>
                          <div className="text">{employee?.data?.religion || "N/A"}</div>
                        </li>
                        <li>
                          <div className="title">Marital status</div>
                          <div className="text">{employee?.data?.maritalStatus || "N/A"}</div>
                        </li>
                        <li>
                          <div className="title">No. of children</div>
                          <div className="text">{employee?.data?.children ?? "N/A"}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {employee.data.id && <ListEmployeeEmergencyContacts employeeId={employee?.data?.id} />}
              </div>
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <h3 className="card-title">Bank information</h3>
                      <ul className="personal-info">
                        <li>
                          <div className="title">Source of income</div>
                          <div className="text">{employee?.data?.sourceOfIncome ?? "N/A"}</div>
                        </li>
                        <li>
                          <div className="title">Bank account No.</div>
                          <div className="text">{employee?.data?.accountNumber ?? "N/A"}</div>
                        </li>
                        {/* <li>
                                            <div className="title">IFSC Code</div>
                                            <div className="text">ICI24504</div>
                                        </li>
                                        <li>
                                            <div className="title">PAN No</div>
                                            <div className="text">TC000Y56</div>
                                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="pro-overview tab-pane fade show active">
            {selectedSection === "Salary" && employee.data.id && <EmployeeSalary employee={employee.data} />}
          </div>
          <div className="pro-overview tab-pane fade show active">
            {selectedSection === "Documents" && employee.data.id && <ListEmployeeDocs employee={employee.data} />}
          </div>
          <div className="pro-overview tab-pane fade show active">
            {selectedSection === "Allowances" && employee.data.id && <ListAllowances employeeId={employee.data.id} />}
          </div>
          <div className="pro-overview tab-pane fade show active">
            {selectedSection === "OverTimes" && employee.data.id && <ListOverTimes employeeId={employee.data.id} />}
          </div>
          <div className="pro-overview tab-pane fade show active">
            {selectedSection === "Deductions" && employee.data.id && <ListDeductions employeeId={employee.data.id} />}
          </div>
          <div className="pro-overview tab-pane fade show active">
            {selectedSection === "Leaves" && employee.data.id && <ListLeaves employeeId={employee.data.id} />}
          </div>

          <div className="pro-overview tab-pane fade show active">
            {selectedSection === "Encashment" && employee.data.id && <ListEncashments employeeId={employee.data.id} />}
          </div>
        </div>
      </div>
      {employee?.data && employee?.data?.contractStatus === false && (
        <div
          style={{
            height: "100vh",
            width: "90%",
            backgroundColor: "black",
            opacity: 0.7,
            position: "fixed",
            top: 0,
          }}
        >
          <div
            style={{
              // width: "300px",
              marginTop: "100px",
              width: "80vw",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img className="w-25" src="/assets/img/terminated.png" alt="" />
          </div>
          {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            marginTop: "50px",
          }}
        >
          <h1 className="alert text-danger text-center">Terminated</h1>
          <h2 className="alert alert-danger text-center">
            This employee has been terminated.
          </h2>
        </div> */}
        </div>
      )}
    </>
  );
};

export default Profile;
