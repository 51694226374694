import { ENDPOINTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { Branch, Role, User } from "@models";
import { AccessLevel } from "@viewModels";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

interface Props {
  user: User;
  callback?: () => void;
}

const ChangeRole: React.FC<Props> = ({ callback, user }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [handleAccessLevel, setHandleAccessLevel] = useState("");

  const fetchRoles = useFetch<PaginatedResult<Role>>(
    { endPoint: ENDPOINTS.roles },
    new PaginatedResult<Role>()
  );

  useEffect(() => {
    setIsLoading(fetchRoles.isFetching);
  }, [fetchRoles.isFetching]);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    const obj = {
      userId: user.id,
      roleId: +data.roleId,
      accessLevel: data.accessLevel,
      isActive: data.isActive,
    };

    const res = await httpService(ENDPOINTS.changeRole).update(user.id, obj);

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>Change User Role</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Role</label>
              <select
                className="form-control select"
                name="roleId"
                ref={register({ required: true })}
              >
                <option></option>
                {fetchRoles?.data?.items?.map((r: Role, i: any) => {
                  return (
                    <option
                      key={i}
                      value={r.id}
                      selected={user.roleId === r.id}
                    >
                      {r.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Access Level</label>
              <select
                onChange={(e) => {
                  setHandleAccessLevel(e.target.value);
                }}
                className="form-control select"
                name="accessLevel"
                ref={register({ required: true })}
              >
                <option></option>
                {Object.keys(AccessLevel).map((d, i) => (
                  <option
                    key={i}
                    value={d}
                    selected={
                      user.accessLevel.toLowerCase() === d.toLowerCase()
                    }
                  >
                    {d}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="row mt-1">
          <div className="d-flex justify-content-start">
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="isActive"
                id="isActive"
                ref={register()}
                defaultChecked={user.isActive}
              />
              <label className="form-check-label pt-1" htmlFor="isActive">
                {"User is active"}
              </label>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <input
            type="submit"
            name="time"
            className="btn btn-danger"
            disabled={isLoading}
            value={isLoading ? "Please wait..." : "Update"}
          />
        </div>
      </form>
    </>
  );
};

export default ChangeRole;
