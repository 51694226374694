import { ENDPOINTS, httpService, PaginatedResult, PagingOptions } from "@api";
import { AddLeave } from "@components";
import { useFetch } from "@hooks";
import { Leave } from "@models";
import {
  Action,
  ComplexHeader,
  PrintComponent,
  Table,
  TableVerticalConfigs,
} from "@shared";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ExportData } from "@shared";
import { RiAddFill } from "react-icons/ri";

interface Props {
  employeeId?: number;
  readOnlyMode?: boolean;
  query?: URLSearchParams;
}

const ListLeaves: React.FC<Props> = ({
  employeeId,
  query,
  readOnlyMode = false,
}) => {
  const MySwal = withReactContent(Swal);

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchData = useFetch<PaginatedResult<Leave>>(
    {
      endPoint: ENDPOINTS.employeeLeaves,
      id: employeeId,
      reload: reload,
      queryStrings: query,
    },
    new PaginatedResult<Leave>()
  );

  useEffect(() => {}, [fetchData?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "leaveType?.name", title: "Name" },
    { key: "from", title: "from", format: "date" },
    { key: "to", title: "to", format: "date" },
    { key: "numberOfDays", title: "number Of Days" },
    { key: "reason", title: "Remarks" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const onButtonClick = (e: any, a?: Leave) => {
    if (!employeeId) return;
    e !== null && e.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <AddLeave
          employeeId={employeeId}
          leave={a}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (l: Leave) => {
        onButtonClick(null, l);
      },
      title: "Edit",
      color: "warning",
      disable: (v: Leave) =>
        v.leaveType?.name !== "Annual Leave" &&
        new Date(v.createdAt).getFullYear() !== new Date().getFullYear(),
    },
    {
      key: "",
      actionType: "badge",
      click: (l: Leave) => {
        onDeleteClickHandler(l);
      },
      title: "Delete",
      color: "danger",
      disable: (v: Leave) =>
        v.leaveType?.name !== "Annual Leave" &&
        new Date(v.createdAt).getFullYear() !== new Date().getFullYear(),
    },
  ];

  const onDeleteClickHandler = (l: Leave) => {
    Swal.fire({
      title: "Leave Record Deletion",
      text: `Are you sure you want to delete this record.`,
      icon: "warning",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I confirm!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const res = await httpService(ENDPOINTS.leaves).delete(l.id);
        if (res.status === 204) {
          setReload((prev) => (prev === undefined ? true : !prev));
          Swal.fire(
            "Done!",
            "Leave record has been deleted successfully.",
            "success"
          );
        } else {
          Swal.fire(
            "Oops!",
            "An error occurred, please try again later.",
            "error"
          );
        }
      },
    });
  };

  const renderTable = () => {
    if (readOnlyMode)
      return (
        <Table
          class="table table-striped table-hover mb-0"
          data={fetchData.data ?? []}
          headers={headers}
          isFetchingPage={fetchData.isFetching}
          showCounter
          // onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      );

    return (
      <Table
        actions={actions}
        class="table table-striped table-hover mb-0"
        data={fetchData.data ?? []}
        headers={headers}
        isFetchingPage={fetchData.isFetching}
        showCounter
        paginationClass="row mt-3"
      />
    );
  };

  return (
    <>
      {readOnlyMode ? (
        renderTable()
      ) : (
        <div className="col-10 offset-1 d-flex">
          <div className="card profile-box flex-fill">
            <div className="card-body">
              <div className="d-flex justify-content-between mb-1">
                <h3>Leaves</h3>
                <div className="d-flex justify-content-end">
                  <button
                    className={`btn btn-sm btn-outline-info rounded-circle m-1 float-right`}
                    onClick={(e) => onButtonClick(e)}
                  >
                    <RiAddFill />
                  </button>
                </div>
              </div>

              <div className="row" id="leavescontents">
                <div
                  className="d-flex justify-content-end d-none"
                  id="DocumentHeader"
                >
                  <small>{`Printed on: ${new Date()}`}</small>
                </div>
                {renderTable()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListLeaves;
