import { ENDPOINTS, Operation, PaginatedResult, httpService } from "@api";
import { NewKRA } from "@components";
import { useFetch } from "@hooks";
import { KRA } from "@models";
import {
  Action,
  ComplexHeader,
  ExportData,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import { KRAGroup } from "core/models/KRAGroup";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ListKRAs = () => {
  const [queryStrings] = useSearchParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchKRAs = useFetch<PaginatedResult<KRA>>(
    {
      endPoint: ENDPOINTS.kras,
      queryStrings: queryStrings,
      reload: reload,
    },
    new PaginatedResult<KRA>()
  );

  const fetchGroups = useFetch<PaginatedResult<KRAGroup>>(
    {
      endPoint: ENDPOINTS.kraGroups,
      ignorePagination: true,
    },
    new PaginatedResult()
  );

  useEffect(() => {}, [fetchKRAs?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "name", title: "KRA" },
    { key: "group?.name", title: "Group" },
    { key: "createdBy", title: "By" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const filters: Filterable<KRA>[] = [
    { key: "name", title: "name", format: "text", operation: Operation.like },
    {
      key: "groupId",
      title: "Group",
      format: "select",
      data: fetchGroups?.data?.items?.map((g) => ({ id: g.id, name: g.name })),
    },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (d: KRA) => {
        onAddUserClickHandler(d);
      },
      title: "Edit",
      color: "warning",
    },
    {
      key: "",
      actionType: "badge",
      click: (d: KRA) => {
        onDeleteKRA(d);
      },
      title: "Delete",
      color: "danger",
    },
  ];

  const onAddUserClickHandler = (d?: KRA) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <NewKRA
          selectedKRA={d}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const onDeleteKRA = (d: KRA) => {
    Swal.fire({
      title: "Are you sure you want to delete this KRA?",
      text: "By confirming this, this process is irrevocable, and this allowance will be permanently deleted.",
      icon: "warning",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const res = await httpService(ENDPOINTS.kras).delete(d.id);
        if (res.status === 204) {
          setReload((prev) => (prev === undefined ? true : !prev));
          Swal.fire("Done!", "KRA has been removed", "success");
        }
      },
    });
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const onExport: ExportData = {
    data: () => excelData(),
    fileName: `Key Result Areas - ${new Date().toLocaleDateString()}`,
  };

  const excelData = async () => {
    const res = await httpService(ENDPOINTS.kras, "", {
      ignorePagination: true,
    }).getAll();
    if (res.status === 200) {
      return (res.data as PaginatedResult<KRA>)?.items.map((e, idx) => ({
        No: idx + 1,
        Name: e.name,
        Group: e?.group?.name,
      }));
    }

    return [];
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Key Result Areas</h3>
        </div>
        <div className="col-auto float-end ms-auto">
          <button
            className="btn btn-sm btn-primary rounded-circle m-1"
            onClick={() => onAddUserClickHandler()}
          >
            <FiPlus />
          </button>
          <TableVerticalConfigs exportAsXSLS={onExport} filters={filters} />
        </div>
      </div>
      <div className="col-8 offset-2 mt-3">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={fetchKRAs?.data ?? []}
          headers={headers}
          isFetchingPage={fetchKRAs.isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>
    </div>
  );
};

export default ListKRAs;
