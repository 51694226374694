import { ENDPOINTS, httpService } from '@api';
import { Department, EmergencyContact } from '@models';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';


interface Props {
    employeeId: number;
    callback?: () => void;
    eContact?: EmergencyContact;
}

const AddEmergencyContact: React.FC<Props> = ({ callback, employeeId, eContact }) => {
    const { register, handleSubmit, errors } = useForm();

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: any, e: any) => {
        setIsLoading(true);

        const emContact: Partial<EmergencyContact> = {
            employeeId,
            name: data.name,
            relationship: data.relationship,
            contact: data.contact
        }

        if (eContact?.id) {
            emContact.id = eContact.id;
            await httpService(ENDPOINTS.emergencyContacts).update(eContact.id, emContact);
        } else {
            await httpService(ENDPOINTS.emergencyContacts).post(emContact);
        }
        // if (res.status === 201) {
        //     Swal.fire({ icon: 'success', text: 'New Department has been successfully registered.', showConfirmButton: false });
        // }

        callback?.();
        setIsLoading(false);
        e.target.reset();
    }

    return (
        <>
            <h4>{eContact?.id ? 'Edit Emergency Contact' : 'Add Emergency Contact'} </h4>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label>Name</label>
                            <div className="col-12">
                                <input type="text" defaultValue={eContact?.name ?? ''} className="form-control" name='name' ref={register({ required: true })} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label>Relationship</label>
                            <div className="col-12">
                                <input type="text" defaultValue={eContact?.relationship ?? ''} className="form-control" name='relationship' ref={register({ required: true })} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <div className="form-group">
                            <label>Contact</label>
                            <div className="col-12">
                                <input type="text" defaultValue={eContact?.contact ?? ''} className="form-control" name='contact' ref={register({ required: true })} />
                            </div>
                        </div>
                    </div>
                </div>

                <input
                    type="submit"
                    name="time"
                    className="btn btn-primary"
                    disabled={isLoading}
                    value={isLoading ? "Please wait..." : eContact?.id ? "Update" : "Register"}
                />
            </form>
        </>
    )
}

export default AddEmergencyContact