import { ENDPOINTS, PaginatedResult } from "@api";
import { ChangeSalary } from "@components";
import { useFetch } from "@hooks";
import { Employee, SalaryHistory } from "@models";
import { ComplexHeader, Table } from "@shared";
import { useEmployeeStore } from "@stores";
import React, { useEffect, useState } from "react";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface Props {
  employee: Employee;
}

const EmployeeSalary: React.FC<Props> = ({ employee }) => {
  const calculateSalary = useEmployeeStore((s) => s.calculateSalary);

  const MySwal = withReactContent(Swal);

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchEmployeeSalaryHistory = useFetch<PaginatedResult<SalaryHistory>>(
    { endPoint: ENDPOINTS.salaryHistories, id: employee.id, reload: reload },
    new PaginatedResult<SalaryHistory>()
  );

  useEffect(() => {}, [fetchEmployeeSalaryHistory?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "amount", title: "Amount" },
    { key: "description", title: "Description" },
    { key: "createdAt", title: "@", format: "date" },
    {
      key: "isRaised",
      title: "Direction",
      formatter: (isRaised) =>
        isRaised ? <FiArrowUp size={16} /> : <FiArrowDown size={16} />,
      renderer: {
        condition: (u: SalaryHistory) => u.isRaised === true,
        ifTrue: "badge badge-success",
        ifFalse: "badge badge-danger",
      },
    },
  ];

  const onPersonalInfoEditClickHandler = (e: any) => {
    e.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 800,
      html: (
        <ChangeSalary
          employee={employee}
          callback={() => window.location.reload()}
        />
      ),
    });
  };

  return (
    <div className="row">
      <div className="col-5 d-flex">
        <div className="card profile-box flex-fill">
          <div className="card-body">
            <h3 className="card-title">
              Salary Info.
              <a
                href="!#"
                onClick={onPersonalInfoEditClickHandler}
                className="edit-icon"
                data-bs-toggle="modal"
                data-bs-target="#personal_info_modal"
              >
                <i className="fa fa-pencil" />
              </a>
            </h3>
            <ul className="personal-info">
              <li>
                <div className="title">Salary Amount</div>
                <div className="text">{employee?.salary ?? "N/A"}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-7 d-flex">
        <div className="card profile-box flex-fill">
          <div className="card-body">
            <h3 className="card-title">Salary History</h3>
            <Table
              class="table table-striped table-hover mb-0"
              data={fetchEmployeeSalaryHistory?.data ?? []}
              headers={headers}
              isFetchingPage={fetchEmployeeSalaryHistory.isFetching}
              showCounter
              // onPageChange={handlePageChange}
              paginationClass="row mt-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeSalary;
