import { ENDPOINTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { Leave, LeaveType } from "@models";
import { DateHelpers } from "@shared";
import { useEmployeeStore } from "@stores";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  employeeId: number;
  leave?: Leave;
  callback?: () => void;
}

const AddLeave: React.FC<Props> = ({ callback, leave, employeeId }) => {
  const calculateRemainingLeaves = useEmployeeStore(
    (s) => s.calculateRemainingLeaves
  );

  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState(0);
  const [reamaingDays, setReamaingDays] = useState<number | null>(null);

  const fetchLeaveTypes = useFetch<PaginatedResult<LeaveType>>(
    { endPoint: ENDPOINTS.leaveTypes },
    new PaginatedResult<LeaveType>()
  );

  useEffect(() => {
    if (fetchLeaveTypes?.data?.items?.length > 0)
      if (leave?.id) {
        setSelectedLeaveTypeId(leave?.leaveTypeId);
      }
  }, [leave, fetchLeaveTypes.isFetching]);

  useEffect(() => {
    if (selectedLeaveTypeId > 0) {
      (async function () {
        var res = await calculateRemainingLeaves(
          employeeId,
          selectedLeaveTypeId,
          new Date(leave?.from ?? new Date()).getFullYear()
        );
        setReamaingDays(res);
        const leaveType = fetchLeaveTypes.data.items.filter(
          (f) => f.id === selectedLeaveTypeId
        )[0];

        (document.getElementById("numberOfDays") as HTMLInputElement).value =
          leaveType.numberOfDays.toString();
        (document.getElementById("remainedDays") as HTMLInputElement).value =
          res?.toString() ?? "N/A";
        if (leaveType.isAnnualLeave) {
          (
            document.getElementById("allowedDaysDisplay") as HTMLInputElement
          ).style.display = "none";
        } else {
          (
            document.getElementById("allowedDaysDisplay") as HTMLInputElement
          ).style.display = "block";
        }
      })();
    }
  }, [selectedLeaveTypeId, employeeId]);

  const calculateNumberOfDays = (from: string, to: string) => {
    const date1 = new Date(from).getTime();
    const date2 = new Date(to).getTime();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays + 1;
  };

  const onSubmit = async (data: any, e: any) => {
    if (!employeeId) return;
    if (!reamaingDays) return alert("Remaining days are 0");
    const nOfDays = calculateNumberOfDays(data.from, data.to);
    if (nOfDays > reamaingDays)
      return alert(
        `remaining days ${reamaingDays} can not be less than the number of requested days ${nOfDays}.`
      );

    setIsLoading(true);

    let dType: Partial<Leave> = {
      employeeId: employeeId,
      leaveTypeId: data.leaveTypeId,
      from: data.from,
      to: data.to,
      reason: data.reason,
    };

    if (leave?.id) {
      dType.id = leave.id;
      await httpService(ENDPOINTS.leaves).update(leave.id, dType);
    } else {
      await httpService(ENDPOINTS.leaves).post(dType);
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>{leave?.id ? "Edit" : "Add"} Leave</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="form-group">
            <label>leave Type</label>
            <select
              className="form-control select"
              name="leaveTypeId"
              onChange={(e) => setSelectedLeaveTypeId(+e.target.value)}
              ref={register({ required: true })}
            >
              <option></option>
              {fetchLeaveTypes?.data?.items?.map((r: LeaveType, i: any) => {
                return (
                  <option
                    key={i}
                    value={r.id}
                    selected={r.id === leave?.leaveTypeId}
                  >
                    {r.name}
                  </option>
                );
              })}
            </select>
            <span className="text-danger">
              {errors.leaveTypeId && <span>This field is required</span>}
            </span>
          </div>
        </div>
        {selectedLeaveTypeId > 0 && (
          <>
            <div className="row">
              <div className="col" id="allowedDaysDisplay">
                <div className="form-group">
                  <label>Number of allawed days</label>
                  <div className="col-12">
                    <input
                      type="number"
                      id="numberOfDays"
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Remained days</label>
                  <div className="col-12">
                    <input
                      type="number"
                      id="remainedDays"
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>From</label>
              <div className="col-12">
                <input
                  defaultValue={DateHelpers.setInputDate(leave?.from)}
                  type="date"
                  className="form-control"
                  name="from"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.from && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>to</label>
              <div className="col-12">
                <input
                  defaultValue={DateHelpers.setInputDate(leave?.to)}
                  type="date"
                  className="form-control"
                  name="to"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.to && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="form-group">
              <label htmlFor="reason">Remarks</label>
              <textarea
                defaultValue={leave?.reason}
                className="form-control mb-4"
                name="reason"
                id="reason"
                aria-label="With textarea"
                ref={register({ required: true })}
              ></textarea>
              <span className="text-danger">
                {errors.reason && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <small className="text-muted mt-1 pt-1 h6">
            *Start and end dates are included
          </small>
          <input
            type="submit"
            name="time"
            className="btn btn-primary"
            disabled={isLoading}
            value={
              isLoading
                ? "Please wait..."
                : fetchLeaveTypes.isFetching
                ? "Loading..."
                : "Register"
            }
          />
        </div>
      </form>
    </>
  );
};

export default AddLeave;
